import { requireImgAssets } from '@/utils/utils'

const data = {
  internationalLogisticsService: ($pconf) => [
    {
      title: 'modules.home.intService', // 国际物流服务
      subTitle: 'modules.home.intTips', // 安全、高效、高品质的国际物流服务
      children: [
        {
          title: 'modules.home.courierService', // 快递服务
          img: requireImgAssets('@/assets/img/home/product_01.png'),
          imgAlt: 'kuaidi',
          path: '/product',
          params: { query: { subProduct: 'express' } },
          img_bg: requireImgAssets('@/assets/img/home/product_bg_01.png'),
          img_mb: requireImgAssets('@/assets/img/home/product_mb_01.png'),
          track: 'express_delivery_click|快递服务点击率',
          description:
            'modules.logisticsService.productList.expressDescription',
          children: [
            {
              title: 'modules.logisticsService.productList.fast', // 国际标快
              subTitle: 'modules.home.tip1', // 精准时效，通过各环节均有标准化流程保障，提供门到门的高品质快递服务
              path: '/product/fast',
              params: { query: { isProduct: 'product' } },
            },
            {
              title: 'modules.logisticsService.productList.discts', // 国际特惠
              subTitle: 'modules.home.tip2', // 为满足客户非紧急物品寄递需求而推出的经济型国际快件服务
              path: '/product/discts',
              params: { query: { isProduct: 'product' } },
            },
            // {
            //   title: 'modules.logisticsService.productList.heavy', // 国际重货
            //   subTitle: 'modules.home.tip7', // 为满足客户非紧急物品寄递需求而推出的经济型国际快件服务
            //   path: '/product/heavy',
            //   params: { query: { isProduct: 'product' } },
            // },
            {
              title: 'modules.logisticsService.productList.bulk', // 国际大件
              subTitle: 'modules.logisticsService.productList.bulkDescription', // 国际大件是为了满足客户寄递较大重量段物品而推出的时效稳定、具有较高性价比的快递服务
              path: '/product/bulk',
              params: { query: { isProduct: 'product' } },
            },
            {
              title: 'modules.home.more', // 了解更多
              isMore: true,
              img: '~/assets/img/arrow_right.png',
              path: '/product',
              params: { hash: 'product' },
            },
          ],
        },
        {
          title: 'modules.home.electService', // 电商服务
          img: requireImgAssets('@/assets/img/home/product_02.png'),
          imgAlt: 'kuaidi',
          path: '/product',
          params: { query: { subProduct: 'e-commerce' } },
          img_bg: requireImgAssets('@/assets/img/home/product_bg_02.png'),
          img_mb: requireImgAssets('@/assets/img/home/product_mb_02.png'),
          track: 'ecommerce_service_click|电商服务点击率',
          description:
            'modules.logisticsService.productList.ecommerceDescription',
          children: [
            {
              title: 'modules.logisticsService.productList.sfbuy', // 顺丰集运
              subTitle: 'modules.home.tip6', // 专为跨境B2C电商卖家量身定制的高效物流服务
              path: '/product/sfbuy',
              params: { query: { isProduct: 'product' } },
            },
            {
              title: 'modules.home.more', // 了解更多
              isMore: true,
              img: '~/assets/img/arrow_right.png',
              path: '/product',
              params: { hash: 'product' },
            },
            {
              title: 'modules.logisticsService.productList.delivery', // 国际电商专递
              subTitle: 'modules.home.tip3', // 专为跨境B2C电商卖家量身定制的高效物流服务
              path: '/product/delivery',
              params: { query: { isProduct: 'product' } },
            },
          ],
        },
      ],
    },
  ],
}

export default data
