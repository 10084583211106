
// import Animation from '@/components/animation'

export default {
  components: {
    // Animation,
  },
  props: {
    clientData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: {
        0: 0,
        1: 0,
      },
      product_wrap_imgUrl: '~/assets/img/home/solution_bg1.jpg',
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },
  mounted() {
    console.log('clientData---', this.clientData)
    // debugger
  },
  methods: {
    change(index, idx) {
      if (this.current[index] !== idx) {
        this.current[index] = idx
      }
    },
  },
}
